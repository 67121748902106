import { Skeleton, Radio, notification } from "antd";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { useCallback, useEffect, useState } from "react";

import { Db, Functions } from "./FirebaseConfig";
import { useUserManagerReducer, SaveStatus } from "./UserManagerReducer";

import "./UserManager.css"


const listParentStudentLists = httpsCallable(
  Functions(), "listparentstudentlists",
);


function useUsers() {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    let ignore = false;

    const usersCollection = collection(Db(), "users");
    const usersQuery = query(usersCollection, orderBy("lastName", "asc"));
    const unsubscribe = onSnapshot(usersQuery, results => {
      if (ignore) {
        return;
      }
      setUsers(results.docs.map(u => u.data()));
    });

    return () => {
      ignore = true;
      unsubscribe();
    };
  }, []);

  return users;
}

function useMailingLists() {
  const [mailingLists, setMailingLists] = useState([]);

  useEffect(() => {
    let ignore = false;

    listParentStudentLists().then(result => {
      if (!ignore) {
        setMailingLists(result.data.lists);
      }
    });

    return () => {
      ignore = true
    };
  }, []);

  return mailingLists;
}


function UserManager(props) {
  const users = useUsers();
  const [userManager, umDispatcher] = useUserManagerReducer();

  if (props.claims.userManager !== true) {
    return <></>;
  }

  const userRows = users.map((u, i) => {
    return (
      <div key={`u:${i}`} className="userTableRow" onClick={e => {
        e.preventDefault();
        umDispatcher({ action: "loadUser", user: u });
      }}>
        <div key={`u:lastName:${i}`}>{u.lastName}</div>
        <div key={`u:firstName:${i}`}>{u.firstName}</div>
        <div key={`u:emailAddress:${i}`}>{u.emailAddress}</div>
        <div key={`u:roles:${i}`}>{u.roles.join(", ")}</div>
      </div>
    );
  });

  return (
    <section className="userManager">
      <h2>UserManager</h2>
      <EditUserArea userManager={userManager} umDispatcher={umDispatcher} />
      <div className="userTablePane">
        <div className="userTable">
          <div>Last Name</div>
          <div>First Name</div>
          <div>Email</div>
          <div>Roles</div>
          {userRows}
        </div>
      </div>
    </section>
  );
}

function EditUserArea(props) {
  const { userManager, umDispatcher } = props;

  return (
    <div className="addUserArea">
      <button disabled={userManager.editing} onClick={e => {
        e.preventDefault();
        umDispatcher({ action: "newUser" });
      }}>Add User</button>
      <UserFields userManager={userManager} umDispatcher={umDispatcher} />
    </div>
  );
}

function UserFields(props) {
  const { userManager, umDispatcher } = props;
  const { editing, saveStatus, savedUser } = userManager;
  const {
    courseAccessIds,
    emailAddress,
    firstName,
    lastName,
    roles,
    graduationYear,
    mailingLists: selectedMailingLists,
    licenseName,
  } = userManager.currentUser;
  const mailingLists = useMailingLists();

  const filterLists = useCallback(l => {
    return l.filter(
      m => roles.filter(r => m.includes(r)).length > 0 ||
        m.startsWith(`class-of-${graduationYear}@`)
    )
      .toSorted();
  }, [roles, graduationYear]);

  useEffect(() => {
    let ignore = false

    if (selectedMailingLists) {
      const currentListsString = JSON.stringify(selectedMailingLists);
      const filteredLists = filterLists(selectedMailingLists);
      const filteredListsString = JSON.stringify(filteredLists);

      if (!ignore && currentListsString !== filteredListsString) {
        umDispatcher(
          { action: "setFields", fields: { mailingLists: filteredLists } }
        );
      }
    }

    return () => {
      ignore = true
    };
  }, [filterLists, selectedMailingLists, umDispatcher]);

  const checkSave = () => {
    let messages = [];
    if (!firstName) {
      messages.push("Please specify a first name");
    }
    if (!lastName) {
      messages.push("Please specify a last name");
    }
    if (!emailAddress) {
      messages.push("Please specify an email address");
    }
    if (graduationYear < (new Date()).getFullYear()) {
      messages.push("Please specify a future graduation year");
    }
    if (roles.length < 1) {
      messages.push("Please specify a portal role");
    }
    if (!licenseName) {
      messages.push("Please specify a license type");
    }
    return messages;
  }

  if (saveStatus) {
    const statusMap = {
      [SaveStatus.Done]: "✅",
      [SaveStatus.Error]: "🚫",
      [SaveStatus.Pending]: "❔",
    };
    const statusFields = Object.keys(saveStatus).map((s, i) => {
      const icon = statusMap[saveStatus[s]];
      return <div key={`saveStatus:${i}`}>
        {`${icon} ${s}`}
      </div>
    });
    return (
      <div>
        <h3>Progress</h3>
        {statusFields}
      </div>
    );
  }

  if (!editing) {
    return (<></>);
  }

  return (
    <div className="addUserFields">
      <TextField
        label="First Name"
        value={firstName}
        required={true}
        updateValue={v => {
          umDispatcher({ action: "setFields", fields: { firstName: v } })
        }} />
      <TextField
        label="Last Name"
        value={lastName}
        required={true}
        updateValue={v => {
          umDispatcher({ action: "setFields", fields: { lastName: v } })
        }} />
      <TextField
        label="Email"
        value={emailAddress}
        required={true}
        updateValue={v => {
          umDispatcher({ action: "setFields", fields: { emailAddress: v } })
        }} />
      <NumberField
        label="Graduation Year"
        value={graduationYear}
        updateValue={v => {
          umDispatcher({ action: "setFields", fields: { graduationYear: v } })
        }} />
      <MultiValueField
        label="Roles"
        required={true}
        value={roles}
        choices={["student", "parent", "expert", "consultant"]}
        updateValue={v => {
          umDispatcher({ action: "setFields", fields: { roles: v } })
        }} />
      <MultiValueField
        label="Courses"
        value={courseAccessIds}
        choices={[9, 10, 11, 12]}
        valueTransformer={v => {
          const courseMap = {
            9: "Freshman",
            10: "Sophomore",
            11: "Junior",
            12: "Senior",
          };
          return courseMap[v];
        }}
        updateValue={v => {
          umDispatcher({ action: "setFields", fields: { courseAccessIds: v } })
        }} />
      <MultiValueField
        label="Mailing Lists"
        value={filterLists(selectedMailingLists || [])}
        choices={filterLists(mailingLists || [])}
        updateValue={v => {
          umDispatcher({ action: "setFields", fields: { mailingLists: v } })
        }} />
      <div className="formField">
        <label>License</label>
        <Skeleton active={true} loading={licenseName === null} />
        {
          licenseName === null ||
          <Radio.Group
            value={licenseName}
            buttonStyle="solid"
            optionType="button"
            onChange={e => {
              e.preventDefault();
              umDispatcher(
                { action: "setFields", fields: { licenseName: e.target.value } }
              );
            }}
            options={[
              { value: "", label: "Unspecified" },
              { value: "EE_PACKAGE", label: "EE_PACKAGE" },
              { value: "EEI_GRADE_LEVEL", label: "EEI_GRADE_LEVEL" },
            ]} />
        }
      </div>
      <div className="buttonArea">
        <button disabled={false} onClick={e => {
          e.preventDefault();
          const messages = checkSave();
          if (messages.length > 0) {
            notification.error({
              message: "Issues with Saving User",
              description: (
                <>
                  {messages.map((v, i) => <p key={`notif:m:${i}`}>{v}</p>)}
                </>
              ),
              showProgress: true,
            });
            return;
          }
          umDispatcher({ action: "saveUser" });
        }}>Save</button>
        <button onClick={e => {
          e.preventDefault();
          umDispatcher({ action: "reset" });
        }}>Cancel</button>
        <button disabled={!savedUser} onClick={e => {
          e.preventDefault();
          umDispatcher({ action: "deleteUser" });
        }}>Delete</button>
      </div>
    </div>
  );
}

function TextField(props) {
  const classes = props.required ? "formField required" : "formField";
  return (
    <div className={classes}>
      <label>{props.label}</label>
      <input type="text" value={props.value} onChange={e => {
        e.preventDefault();
        props.updateValue(e.target.value);
      }} />
    </div>
  );
}

function NumberField(props) {
  const classes = props.required ? "formField required" : "formField";
  return (
    <div className={classes}>
      <label>{props.label}</label>
      <input type="number" value={props.value.toString()} onChange={e => {
        e.preventDefault();
        props.updateValue(parseInt(e.target.value || "0"));
      }} />
    </div>
  );
}

function MultiValueField(props) {
  const classes = props.required ? "formField required" : "formField";
  const valueTransformer = props.valueTransformer || (v => v);
  const choicesClasses = (
    props.value.length > 0 ? "formFieldChoices" : "formFieldChoices empty"
  );
  const fieldValues = props.choices.map((c, i) => {
    return (
      <MultiValueFieldValue
        prefix="userManager:addUser"
        key={`userManager:addUser:${i}`}
        value={c}
        displayValue={valueTransformer(c)}
        chosen={props.value.includes(c)}
        updateValue={() => {
          const newValues = (
            props.value.includes(c)
              ? props.value.filter(v => v !== c)
              : props.value.concat([c])
          );
          props.updateValue(newValues);
        }} />
    );
  });

  return (
    <div className={classes}>
      <label>{props.label}</label>
      <div className={choicesClasses}>
        {fieldValues.length > 0 ? fieldValues : "(None Specified)"}
      </div>
    </div>
  );
}

function MultiValueFieldValue(props) {
  const classes = props.chosen ? "formFieldChoice selected" : "formFieldChoice";

  return (
    <div
      className={classes}
      key={`${props.prefix}:formFieldChoice:${props.value}`}
      onClick={e => {
        e.preventDefault();
        props.updateValue();
      }}>
      {props.displayValue}
    </div>
  );
}


export { UserManager };